import React, { useCallback, useState } from "react";
import AddExistingContacts from "../../../../../global components/AddExistingContacts";
import AddToGroup from "../../../../../global components/AddToGroup";
import TabsTemplate from "../../../../../global components/TabsTemplate/TabsTemplte";

import s from "./ContectsTab.module.scss";

type Props = {
  handleContact: (contactIds: string[]) => void;
  handleGroup: (groupIds: Array<{ name: string; id: string }>) => void;
};

function ContactsTab({ handleContact, handleGroup }: Props) {
  const handleContacts = useCallback(
    (contactsIds: string[]) => {
      handleContact(contactsIds);
    },
    [handleContact]
  ); // Memoize handleContacts function

  const handleGroups = useCallback(
    (groupsIds: Array<{ name: string; id: string }>) => {
      handleGroup(groupsIds);
    },
    [handleGroup]
  ); // Mem
  // Helper for pluralization
  // const formatCount = (count: number, singular: string, plural: string) =>
  //   `${count} ${count === 1 ? singular : plural}`;

  const accountTabs = [
    {
      key: "1",
      label: <h2>Contacts</h2>,
      children: (
        <AddExistingContacts handleContacts={handleContacts} messages={true} />
      ),
    },
    {
      key: "2",
      label: <h2>Groups</h2>,
      children: <AddToGroup handleGroups={handleGroups} messages={true} />,
    },
  ];

  return (
    <div className={s.wrapper}>
      {/* <div className={s.helpers}>
        <p>{formatCount(contacts.length, "contact", "contacts")} added</p>
      </div> */}
      <TabsTemplate
        tabItems={accountTabs}
        wrapperClassname="customClass"
        withBorderBottom={true}
      />
    </div>
  );
}

export default ContactsTab;
