import React from "react";
import type { UploadProps } from "antd";
import { Upload } from "antd";
import "./DragAndDrop.scss";

const { Dragger } = Upload;

interface DragAndDropProps {
  onFileChange: (fileData: string | File) => void; // Update to receive file data
  icon?: React.ReactNode; // Optional icon prop
  type?: "csv" | "pdf" | "doc" | "image" |"audio";
}

const DragAndDrop: React.FC<DragAndDropProps> = ({
  onFileChange,
  icon,
  type = "csv",
}) => {
  const acceptedTypes = {
    csv: ".csv",
    pdf: ".pdf",
    doc: ".doc,.docx",
    image: ".jpg,.jpeg,.png,.gif", // Example for image files    audio: ".mp3,.wav", // Example for audio files
    audio: ".mp3,.wav,.m4a,.aac,.ogg,.flac,.aiff,.wma",

  };
  const props: UploadProps = {
    name: "file",
    multiple: false, // Change to false if only a single file is needed
    accept: acceptedTypes[type], // Accept all file types
    beforeUpload: (file) => {
      const reader = new FileReader();
      reader.onload = (e) => {
        const fileContent = e.target?.result;
        if (typeof fileContent === "string") {
          // The file content is a string, handle it as text (for text files)
          onFileChange(fileContent); // Send file data as string to parent
        } else if (fileContent instanceof ArrayBuffer) {
          // If the file is binary (e.g., an image or other non-text file)
          onFileChange(file); // Send the actual file object to parent (file itself, not the content)
        }
      };

      if (file.type.includes("text")) {
        reader.readAsText(file);
      } else {
        reader.readAsArrayBuffer(file); // For binary files (e.g., images, PDFs)
      }

      return false;
    },
  };

  return (
    <Dragger
      progress={{ strokeWidth: 2, showInfo: true }} // Display progress bar
      {...props}
    >
      <div className={"content"}>
        <div className="ant-upload-drag-icon">
          {icon} {/* Use the passed icon here */}
        </div>
        <p className="ant-upload-text">
          <span className={"drag"}> Drag & drop or &nbsp;</span>
          <span className="upload">click to upload</span>
        </p>
      </div>
    </Dragger>
  );
};

export default DragAndDrop;
