import React, { useCallback, useEffect, useRef, useState } from "react";
import s from "./SMS.module.scss";
import clsx from "clsx";
import { Controller, useForm } from "react-hook-form";
import {
  Button,
  DatePicker,
  Input,
  Segmented,
  Spin,
  TimePicker,
  TimePickerProps,
} from "antd";
import SelectField from "../../../../global components/Forms/SelectField/SelectField";
import {
  PaginatedContactGroups,
  singleContactGroup,
} from "../../../../libs/Utils/Types/ContactGroup";
import { UseAuthToken } from "../../../../libs/api/userSession";
import useSWR from "swr";
import {
  parseClientError,
  swrFetcher,
  swrMutationFetcher,
  SwrMutationFetcherArgType,
} from "../../../../libs/api/Endpoints";
import { useNavigate } from "react-router-dom";
import { generateResult } from "../../../../global components/ResultModal/ResultModal";
import ReusableDrawer from "../../../../global components/Drawer/ReuseableDrawer";
import ReusablePopover from "../../../../global components/Popover/ReuseablePopover";
import NewPopOverItems from "../TimePopOver/PopOverItems";
import ArrowDown from "../../../../global components/Icons/ArrowDown";
import dayjs, { Dayjs } from "dayjs";
import PhoneInput from "react-phone-input-2";
import AudioRecorder from "../../../../global components/AudioRecorder/AudioRecorder";
import useSWRMutation from "swr/mutation";
import "react-phone-input-2/lib/style.css";
import Microphone from "../../../../global components/Icons/Microphone/Microphone";
import { useDraft } from "../../../../global components/Context/DraftContext";
import ReusableModal from "../../../../global components/Modal/Modal";
import { capitalize, useQuery } from "../../../../libs/common";
import { MessageDetailsType } from "../../../../libs/Utils/Types/Message";
import { useToggle } from "../../../../global components/Context/NavbarContext";
import ConfirmationModal from "../../../../global components/CofirmationModal/ConfirmationModal";
import { Oval, ThreeDots } from "react-loader-spinner";
import { SuccessDataType } from "../../../../libs/api/types";
import { useMediaQuery } from "react-responsive";
import SetSchedule from "../SetSchedule";
import UploadVoice from "../UploadVoice/UploadVoice";
import Recipients from "../Recipients";
type Inputs = {
  msgTitle: string;
  emailSubject: string;
  recipient: string;
  date: string;
  time: string;
  from: string;
};

function VoiceMessage() {
  const [openGroup, setOpenGroup] = useState<boolean>(false);
  const [schedule, setSchedule] = useState<string | string[]>("");
  const token = UseAuthToken();
  const [openModal, setOpenModal] = useState<boolean>(false);
  const query = useQuery();
  const [openMobile, setOpenMobile] = useState<boolean>(false);
  const [contactIds, setContactIds] = useState<string[]>([]);
  const [contactGroupIds, setContactGroupIds] = useState<string[]>([]);
  const [contactGroupNames, setContactGroupNames] = useState<string[]>([]);

  const handleContacts = (contactsIds: string[]) => {
    setContactIds(contactsIds);
  };

  const handleGroups = (groupsIds: Array<{ name: string; id: string }>) => {
    setContactGroupIds(groupsIds.map((item) => item.id));
    setContactGroupNames(groupsIds.map((item) => item.name));
  };
  const groupId = query.get("groupId");

  const [audio, setAudio] = useState<Blob | null>(null); // Update type to accept Blob
  const [, /*audioURL*/ setAudioURL] = useState<string>("");
  const { trigger: uploadVoiceMessage, isMutating } = useSWRMutation(
    "api/media/upload",
    swrMutationFetcher
  );
  const msgId = query.get("msgId");
  const [date, setDate] = useState<string | null>(null);
  function handleCombined(dates: string | null) {
    if (dates) {
      setDate(dates);
    }
  }
  const { data: group } = useSWR<singleContactGroup, any, any>(
    token && groupId ? [`api/contact-groups/${groupId}`, token] : null,
    swrFetcher
  );
  const {
    data: MessageDetails,
    isLoading,
    error,
  } = useSWR<MessageDetailsType, any, any>(
    token && msgId ? [`api/messages/${msgId}`, token] : null,
    swrFetcher,
    {
      revalidateOnFocus: true,

      shouldRetryOnError: false,
    }
  );
  const [open, setOpen] = useState<boolean>(false);
  const [recipient, setRecipients] = useState<string>("");

  // Fetch contact groups
  const { data: groups, isLoading: loadGroups } = useSWR<
    PaginatedContactGroups,
    any,
    any
  >(token ? [`api/contact-groups`, token] : null, swrFetcher, {
    shouldRetryOnError: false,
  });
  const { data: Recipient, isLoading: loading } = useSWR<
    singleContactGroup,
    any,
    any
  >(
    token && recipient ? [`api/contact-groups/${recipient}`, token] : null,
    swrFetcher
  );
  const {
    watch,
    handleSubmit,
    control,
    formState: { errors, isSubmitting },
    reset,
    setValue,
  } = useForm<Inputs>({ shouldFocusError: false });
  const hasUpdated = useRef(false); // Tracks if state has been set

  const navigate = useNavigate();
  const handleAudioSave = (blob: Blob) => {
    setAudio(blob);
  };
  const { isTrue, toggle } = useToggle();
  const [type, setType] = useState<"voice" | "voice_call">("voice");

  const onSubmit = async (data: Inputs) => {
    if (!audio) {
      generateResult("error", "You must record a message");
      return;
    }
    if (contactGroupIds.length === 0 && contactIds.length === 0) {
      generateResult(
        "error",
        "No recipients!",
        "Select a group with at least one contact or select the contacts you want to send message to"
      );
      return;
    }
    try {
      if (audio) {
        const formData = new FormData();
        formData.append("media", audio);
        formData.append("type", "Message");
        type Response = {
          secure_url: string;
        };
        const response: SuccessDataType<Response> = await uploadVoiceMessage({
          token,
          requestOption: {
            method: "POST",
            body: formData,
          },
        });
        if (response && response.data.secure_url) {
          setAudioURL(response.data.secure_url);
          if (date) {
            const combinedDateTime = date;

            generateResult(
              "success",
              "Successful",
              "Review your message before sending or check your drafts to edit the message"
            );
            navigate("review", {
              state: {
                ...data,
                type: type,
                contactIds: contactIds,
                contactGroupIds: contactGroupIds,
                contactGroupNames: contactGroupNames,
                audioURL: response.data.secure_url,
                schedule: combinedDateTime,
                msgId: msgID || msgId,
              },
            });

            reset();
          } else {
            generateResult(
              "success",
              "Successful",
              "Review your message before sending or check your drafts to edit the message"
            );
            navigate("review", {
              state: {
                ...data,
                type: type,
                contactIds: contactIds,
                contactGroupIds: contactGroupIds,
                contactGroupNames: contactGroupNames,
                audioURL: response.data.secure_url,
                msgId: msgID || msgId,
              },
            });
          }
        } else {
          generateResult("error", "Audio URL not returned from upload");
        }
      }
    } catch (error) {
      const e = parseClientError(error);
      generateResult(
        "error",
        capitalize(e.message) || "An unexpected error occurred."
      );
    }
  };
  function handleNewGroup() {
    if (!isTrue) {
      toggle();
    }
    setOpenGroup((prev) => !prev);
  }

  const [msgID, setmsgId] = useState<string>("");

  const { trigger: submitMsg } = useSWRMutation(
    `api/messages/${msgID ? msgID : ""}?`,
    swrMutationFetcher
  );
  const { cancelDraft, debounceTimeout } = useDraft();
  const handleDraft = useCallback(() => {
    // Clear the previous timeout if it exists
    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }

    // Set a new timeout
    debounceTimeout.current = setTimeout(async () => {
      try {
        if (!audio) return; // Early return if no audio is available

        type Response = {
          secure_url: string;
        };

        const formData = new FormData();
        formData.append("media", audio);
        formData.append("type", "Message");

        const response: SuccessDataType<Response> = await uploadVoiceMessage({
          token,
          requestOption: {
            method: "POST",
            body: formData,
          },
        });

        if (response?.data?.secure_url) {
          setAudioURL(response.data.secure_url);
          formData.append("body", response.data.secure_url);
        }

        if (watch("msgTitle")) formData.append("title", watch("msgTitle"));
        formData.append("type", type);

        if (contactGroupIds.length) {
          contactGroupIds.forEach((id) => {
            formData.append("contactGroupIds[]", id);
          });
        }
        if (contactIds.length) {
          contactIds.forEach((id) => {
            formData.append("contactIds[]", id);
          });
        }

        formData.append("isDraft", "true");

        const fetcherArg: SwrMutationFetcherArgType = {
          token,
          requestOption: {
            method: msgID ? "PATCH" : "POST",
            body: formData,
          },
        };

        if (
          watch("msgTitle") &&
          (contactGroupIds.length || contactIds.length)
        ) {
          type SubmitResponse = {
            id: string;
          };

          const result: SuccessDataType<SubmitResponse> = await submitMsg(
            fetcherArg
          );

          if (!msgID || !hasUpdated.current) {
            setmsgId(result.data.id);
            hasUpdated.current = true; // Set the flag to prevent further updates
          }
        }
      } catch (error) {
        console.error("Failed to submit draft:", error);
      }
    }, 1500); // 1.5 seconds delay

    return () => {
      if (debounceTimeout.current) {
        clearTimeout(debounceTimeout.current);
      }
    };
  }, [
    debounceTimeout,
    audio,
    uploadVoiceMessage,
    token,
    watch,
    type,
    contactGroupIds,
    contactIds,
    msgID,
    submitMsg,
  ]);

  // Effect to call handleDraft whenever content changes
  useEffect(() => {
    handleDraft();
  }, [audio, handleDraft]);
  const watchedRecipient = watch(["recipient"]);
  useEffect(() => {
    if (watch("recipient")) {
      setRecipients(watch("recipient"));
    }
  }, [watch, watchedRecipient]);

  useEffect(() => {
    if (MessageDetails) {
      setValue("msgTitle", MessageDetails.title);
      // setValue("date", dayjs(MessageDetails.scheduledFor).toISOString());

      // setValue("time", dayjs(MessageDetails.scheduledFor).toISOString());
      setValue("from", MessageDetails.senderName);
      const groupId = groups?.docs.find(
        (group) => group.name === MessageDetails.nameOfContactGroups
      );
      !loadGroups &&
        setValue(
          "recipient",
          typeof groupId?._id === "string" ? groupId?._id : ""
        );
    }
  }, [MessageDetails, groups?.docs, setValue]);
  const [value, setValueType] = useState<string>("Upload voice message");
  useEffect(() => {
    if (msgId) {
      setmsgId(msgId);
    }
  }, [msgId]);
  const [animate, setAnimate] = useState<boolean>(false);
  useEffect(() => {
    if (animate) {
      const timer = setTimeout(() => {
        setAnimate(false);
      }, 500); // Duration of animation (0.5s)
      return () => clearTimeout(timer); // Clean up timer on component unmount
    }
  }, [animate]);
  const isMobile = useMediaQuery({ query: "(max-width: 800px)" });
  const verticalDisplay = useMediaQuery({ query: "(max-width: 924px)" });
  const handleVoice = (voice: Blob) => {
    setAudio(voice);
  };
  return (
    <>
      <ReusableModal
        title="Schedule message"
        handleModal={handleNewGroup}
        isModalOpen={isMobile && openGroup}
        centered
        footer={false}
      >
        <SetSchedule
          handleCombined={handleCombined}
          handleNewGroup={handleNewGroup}
          schedule={msgId ? MessageDetails?.scheduledFor : null}
        />
      </ReusableModal>
      <ReusableDrawer
        title="Schedule message"
        Open={!isMobile && openGroup}
        handleOpen={handleNewGroup}
      >
        <SetSchedule
          handleCombined={handleCombined}
          handleNewGroup={handleNewGroup}
          schedule={msgId ? MessageDetails?.scheduledFor : null}
        />
      </ReusableDrawer>
      {isLoading || error || loadGroups ? (
        <div className={s.spin}>
          <ThreeDots
            visible={true}
            height="50"
            width="50"
            color="#d52b1e"
            radius="9"
            ariaLabel="three-dots-loading"
            wrapperStyle={{
              dispaly: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
              width: "100%",
            }}
            wrapperClass=""
          />
        </div>
      ) : (
        <div className={s.wrapper}>
          <div className={s.emailTitles}>
            <div className={s.msgTitleWrapper}>
              <h1>
                {msgId && MessageDetails
                  ? MessageDetails.title
                  : watch("msgTitle") || "Untitled"}
              </h1>
            </div>
            <div className={s.inputWrapper}>
              <Segmented<string>
                vertical={verticalDisplay && !isMobile}
                options={["Voice message", "Voice broadcast"]}
                onChange={(value) => {
                  if (value === "Voice message") {
                    setType("voice");
                  } else {
                    setType("voice_call");
                  }
                  // string
                }}
                className="custom-segmented"
                //value={value}
              />
              <p className={s.helperText}>
                {type === "voice"
                  ? "Send an audio file that your recipient can play directly."
                  : "Deliver your message as an automated call your recipient listens to."}
              </p>
            </div>
            <div className={s.inputWrapper}>
              <h5 className={s.label}>Message title</h5>
              <Controller
                name="msgTitle"
                control={control}
                rules={{ required: "Message title is required" }}
                render={({ field }) => (
                  <Input
                    {...field}
                    defaultValue={MessageDetails ? MessageDetails.title : ""}
                    count={{
                      show: true,
                      max: 80,
                    }}
                    className={clsx(s.input, {
                      [s.inputError]: errors.msgTitle,
                    })}
                    maxLength={80}
                  />
                )}
              />
              {!errors.msgTitle && (
                <span className={s.helperText}>
                  <p className={s.titleMsg}>
                    This is just an internal title to help organize and locate
                    your message easily. This does not appear to your audience.
                  </p>
                </span>
              )}
              {errors.msgTitle && (
                <span className={clsx(s.errorMessage)}>
                  {errors.msgTitle.message}
                </span>
              )}
            </div>
            <Recipients
              handleContact={handleContacts}
              handleGroup={handleGroups}
            />

            {/* <div className={clsx(s.inputWrapper)}>
            <h5 className={s.label}>From</h5>
            <Controller
              name="from"
              control={control}
              rules={{ required: "Phone number is required" }}
              render={({ field }) => (
                <PhoneInput
                  {...field}
                  country={"ng"} // default country
                  enableSearch={true} // Enable searching countries
                  placeholder="Enter phone number"
                  onChange={field.onChange} // directly update field value
                  containerClass={s.phoneInputContainer} // Added for custom CSS
                  dropdownClass={s.phoneInputDropdown}
                  inputClass={clsx(
                    s.input,
                    { [s.inputError]: errors.from },
                    s.PhoneInput
                  )} // Phone number input field class
                  buttonClass={s.flagDropdown} // Dropdown for flag and country code
                />
              )}
            />
            {errors.from && (
              <span className={clsx(s.errorMessage)}>
                {errors.from.message}
              </span>
            )}
          </div> */}
          </div>

          <div className={s.textEditor}>
            <div className={s.editorBtnGroup}>
              <button
                type="button"
                className={clsx(s.scheduleBtn)}
                onClick={handleNewGroup}
                disabled={isSubmitting}
              >
                Schedule
              </button>
              {/* Button to show scheduling mobile modal */}
              <button
                type="button"
                className={clsx(s.mobileScheduleBtn)}
                onClick={handleNewGroup}
                disabled={isSubmitting}
              >
                Schedule
              </button>
              <button
                type="button"
                className={clsx(s.submitBtn)}
                onClick={handleSubmit(onSubmit)}
                disabled={isSubmitting}
              >
                <Oval
                  visible={isSubmitting}
                  height="16"
                  width="16"
                  color="white"
                  ariaLabel="oval-loading"
                />
                Send now
              </button>
            </div>
            <div className={s.audio}>
              <div className={s.cardTitle}>
                <Segmented<string>
                  style={{ width: "100%" }}
                  options={["Upload voice message", "Create voice note"]}
                  onChange={(value) => {
                    setAudio(null);
                    setAnimate(true);
                    setValueType(value); // string
                  }}
                  className="custom-segmented"
                  value={value}
                />
              </div>
              <div
                className={clsx(s.cardBody, {
                  [s.animate]: value === "Create voice note" && animate,
                  [s.reverseAnimate]:
                    value === "Upload voice message" && animate,
                })}
              >
                {value === "Upload voice message" ? (
                  <UploadVoice handleVoice={handleVoice} />
                ) : (
                  <AudioRecorder
                    body={MessageDetails?.body}
                    onAudioSave={handleAudioSave}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default VoiceMessage;
