import React, { useEffect, useState } from "react";
import { generateResult } from "../../../../global components/ResultModal/ResultModal";
import ArrowDown from "../../../../global components/Icons/ArrowDown";
import ConfirmationModal from "../../../../global components/CofirmationModal/ConfirmationModal";
import { Controller, useForm } from "react-hook-form";
import { Button, DatePicker, TimePicker } from "antd";
import s from "./SetSchedule.module.scss";
import utc from "dayjs/plugin/utc";

import dayjs, { Dayjs } from "dayjs";
import clsx from "clsx";
dayjs.extend(utc);
type Inputs = {
  date: string;
  time: string;
};
type Prop = {
  handleNewGroup: () => void;
  schedule?: string|null;
  handleCombined: (date: string | null) => void;
};
function SetSchedule({ handleNewGroup, schedule, handleCombined }: Prop) {
  const [open, setOpen] = useState<boolean>(false);
  const handleConfirmationModal = () => {
    setOpen((prev) => !prev);
  };
  const {
    watch,
    register,
    handleSubmit,
    control,
    formState: { errors, isSubmitting },
    getValues,
    setValue,
    reset,
  } = useForm<Inputs>({ shouldFocusError: false });
  const combineDateTime = (
    date: string | Dayjs | null,
    time: string | Dayjs | null
  ) => {
    if (date && time) {
      const parsedDate = dayjs(date);
      const parsedTime = dayjs(time);
  
      if (parsedDate.isValid() && parsedTime.isValid()) {
        const combined = parsedDate
          .hour(parsedTime.hour())
          .minute(parsedTime.minute())
          .second(0)
          .millisecond(0);
  
        return combined.utc().toISOString(); // Converts explicitly to UTC
      }
    }
    return null;
  };
  
  const onSubmit = async (data: Inputs) => {
        const date = combineDateTime(data.date, data.time);
        if (date) {
          handleCombined(date);
         
        }
      
  };
  useEffect(() => {
    if (schedule) {
     
      setValue("date", dayjs(schedule).utc().toISOString());
      setValue("time", dayjs(schedule).utc().toISOString());
    }
  }, [schedule, setValue]);
  return (
    <>
      <ConfirmationModal
        handleCancel={handleNewGroup}
        open={open}
        reset={reset}
        handleConfirmationModal={handleConfirmationModal}
      />
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={s.form}>
          <div className={s.scheduleWrapper}>
            <div className={clsx(s.inputWrapper)}>
              <h5 className={clsx(s.inputLabel)}>Select date</h5>
              <Controller
                name="date"
                control={control}
                render={({ field }) => (
                  <DatePicker
                    {...field}
                    // value={
                    //   watch("date")
                    // } // Ensure value is a Day.js object or null
                    onChange={(date, dateString) => {
                      if (date) {
                        const isValidDate = dayjs(date.toString()).isValid();
                        if (isValidDate) {
                          field.onChange(date); // Set the value if valid
                        } else {
                          field.onChange(null); // Clear the field if invalid
                        }
                      }
                    }}
                    defaultValue={schedule ? dayjs(schedule).utc() : null} // Use dayjs to convert the string to a Dayjs object

                    value={watch("date") ? dayjs(watch("date")) : null} // Ensure value is a Day.js object
                    className={clsx(s.input, {
                      [s.inputError]: errors.date,
                    })}
                    placeholder="MM/YYYY"
                    disabledDate={(current) => {
                      const today = dayjs(); // Current date
                      const ninetyDaysFromNow = today.add(90, "days"); // Date 90 days from today
                      return (
                        current &&
                        (current.isBefore(today, "day") ||
                          current.isAfter(ninetyDaysFromNow, "day"))
                      ); // Disable dates before today or beyond the next 90 days
                    }}
                  />
                )}
              />
            </div>
            <div className={clsx(s.inputWrapper)}>
              <h5 className={clsx(s.inputLabel)}>Time</h5>
              <Controller
                name="time"
                control={control}
                render={({ field }) => (
                  <TimePicker
                    defaultValue={schedule ? dayjs(schedule).utc() : null} // Use dayjs to convert the string to a Dayjs object
                    className={clsx(s.input)}
                    onChange={(time, timeString) => {
                      if (time) {
                        field.onChange(time); // Set the value
                      }
                    }}
                    changeOnScroll
                    format="HH:mm"
                    needConfirm={true}
                    suffixIcon={
                      <ArrowDown height={16} width={16} fill="#6A6767" />
                    }
                  />
                )}
              />
            </div>
          </div>
          <div className={s.addContactBtns}>
            <Button
              type="default"
              className={clsx(s.button, s.cancelBtn)}
              onClick={handleConfirmationModal}
            >
              Cancel
            </Button>
            <Button
              type="primary"
              htmlType="submit"
              className={clsx(s.button, s.addContactBtn)}
              onClick={() => {
                generateResult("success", "Schedule set successfully");
                handleNewGroup();
              }}
            >
              Schedule
            </Button>
          </div>
        </div>
      </form>
    </>
  );
}

export default SetSchedule;
