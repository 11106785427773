import React, { useCallback, useEffect, useRef, useState } from "react";
import RichTextEditor from "../../../../global components/React Quill Text Editor/QuilEditor";
import s from "./SMS.module.scss";
import clsx from "clsx";
import { Controller, useForm } from "react-hook-form";
import {
  Button,
  DatePicker,
  Input,
  Spin,
  TimePicker,
  TimePickerProps,
} from "antd";
import SelectField from "../../../../global components/Forms/SelectField/SelectField";
import {
  PaginatedContactGroups,
  singleContactGroup,
} from "../../../../libs/Utils/Types/ContactGroup";
import { UseAuthToken } from "../../../../libs/api/userSession";
import useSWR from "swr";
import {
  parseClientError,
  swrFetcher,
  swrMutationFetcher,
  SwrMutationFetcherArgType,
} from "../../../../libs/api/Endpoints";
import { useNavigate } from "react-router-dom";
import { generateResult } from "../../../../global components/ResultModal/ResultModal";
import ReusableDrawer from "../../../../global components/Drawer/ReuseableDrawer";
import ReusablePopover from "../../../../global components/Popover/ReuseablePopover";
import NewPopOverItems from "../TimePopOver/PopOverItems";
import ArrowDown from "../../../../global components/Icons/ArrowDown";
import dayjs, { Dayjs } from "dayjs";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useQuery } from "../../../../libs/common";
import { MessageDetailsType } from "../../../../libs/Utils/Types/Message";
import useSWRMutation from "swr/mutation";
import { useDraft } from "../../../../global components/Context/DraftContext";
import ReusableModal from "../../../../global components/Modal/Modal";
import ConfirmationModal from "../../../../global components/CofirmationModal/ConfirmationModal";
import { Oval, ThreeDots } from "react-loader-spinner";
import { SuccessDataType } from "../../../../libs/api/types";
import SetSchedule from "../SetSchedule";
import { useMediaQuery } from "react-responsive";
import { useToggle } from "../../../../global components/Context/NavbarContext";
import Recipients from "../Recipients";

type Inputs = {
  msgTitle: string;
  emailSubject: string;
  recipient: string;
  date: string;
  time: string;
  from: string;
};

function SMS() {
  const [content, setContent] = useState<string>("");
  const [openGroup, setOpenGroup] = useState<boolean>(false);
  const [schedule, setSchedule] = useState<string | string[]>("");
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [openMobile, setOpenMobile] = useState<boolean>(false);
const [contactIds, setContactIds] = useState<string[]>([]);
  const [contactGroupIds, setContactGroupIds] = useState<string[]>([]);
  const [contactGroupNames, setContactGroupNames] = useState<string[]>([]);

  const handleContacts = (contactsIds: string[]) => {
    setContactIds(contactsIds);
  };

  const handleGroups = (groupsIds: Array<{ name: string; id: string }>) => {
    setContactGroupIds(groupsIds.map((item) => item.id));
    setContactGroupNames(groupsIds.map((item) => item.name));
  };
  const [msgID, setmsgId] = useState<string>("");
  const [recipient, setRecipients] = useState<string>("");

  const token = UseAuthToken();
  const query = useQuery();
  const msgId = query.get("msgId");
  const [date, setDate] = useState<string | null>(null);
  function handleCombined(dates: string | null) {
    if (dates) {
      setDate(dates);
    }
  }
  // Fetch contact groups
  const { data: groups,isLoading:loadGroups } = useSWR<PaginatedContactGroups, any, any>(
    token ? [`api/contact-groups`, token] : null,
    swrFetcher,
    {
      shouldRetryOnError: false,
    }
  );
  const { data: Recipient, isLoading: loading } = useSWR<
    singleContactGroup,
    any,
    any
  >(
    token && recipient ? [`api/contact-groups/${recipient}`, token] : null,
    swrFetcher
  );
  const {
    data: MessageDetails,
    isLoading,
    error,
  } = useSWR<MessageDetailsType, any, any>(
    token && msgId ? [`api/messages/${msgId}`, token] : null,
    swrFetcher,
    {
      revalidateOnFocus: true,

      shouldRetryOnError: false,
    }
  );
  const {
    watch,
    handleSubmit,
    control,
    formState: { errors, isSubmitting },
    reset,
    setValue,
  } = useForm<Inputs>({ shouldFocusError: false });

  const navigate = useNavigate();

  // Handler to receive edited content
  const handleEdit = (newContent: string) => {
    setContent(newContent);
  };
  const { cancelDraft, debounceTimeout } = useDraft();
  const hasUpdated = useRef(false); // Tracks if state has been set
  const [images, setImages] = useState<Blob[]>([]);

  function htmlToPlainText(htmlString: string): string {
    const tempElement = document.createElement("div");
    tempElement.innerHTML = htmlString;
  
    const textContent: string[] = [];
  
    // Process child nodes to extract only text content
    Array.from(tempElement.childNodes).forEach((node) => {
      if (node.nodeType === Node.TEXT_NODE) {
        const text = node.textContent?.trim();
        if (text) {
          textContent.push(text);
        }
      } else if (node.nodeType === Node.ELEMENT_NODE && (node as HTMLElement).tagName !== "IMG") {
        const elementText = (node as HTMLElement).textContent?.trim();
        if (elementText) {
          textContent.push(elementText);
        }
      }
    });
  
    // Concatenate text with full stops
    const formattedText = textContent.join(". ") + (textContent.length ? "." : "");
  
    return formattedText.trim();
  }
  

  const { trigger: uploadImage, isMutating: Loading } = useSWRMutation(
    "api/media/upload",
    swrMutationFetcher
  );
  const { trigger: submitMsg, isMutating } = useSWRMutation(
    `api/messages/${msgID ? msgID : ""}?`,

    swrMutationFetcher
  );
  const watchedRecipient = watch(["recipient"]);

  const handleImageUpload = async (blob: Blob) => {
    // You can handle the blob here, for example, upload it to a server

    // Optionally, convert the blob to an object URL if you want to display it

    setImages((prevImages) => [...prevImages, blob]);
  };
  useEffect(() => {
    if (watch("recipient")) {
      setRecipients(watch("recipient"));
    }
  }, [watch, watchedRecipient]);

  const onSubmit = async (data: Inputs) => {
    if (!content) {
      generateResult("error", "You must have a body!");
      return;
    }
    if (contactGroupIds.length === 0 && contactIds.length === 0) {
          generateResult(
            "error",
            "No recipients!",
            "Select a group with at least one contact or select the contacts you want to send message to"
          );
          return;
        }
    if (images.length > 5) {
      generateResult("error", "You must have at most one image!");
      let newString = content;
      newString = newString.replace(
        /<img[^>]*src=["']data:image\/[a-zA-Z]+;base64,[^"']+["'][^>]*>/,
        ""
      );
      setContent(newString);
      setImages((prev) => {
        const newArray = [...prev];
        newArray.pop();
        return newArray;
      });
      return;
    }

    try {
      if (date) {
        const combinedDateTime = date;

        // Navigate to the review page with the form data and formatted date/time
        if (images.length > 0) {
          let newString = content;

          // Process each image in the images array
          const imageArray: { publicId: any; secureUrl: any }[] | undefined =
            [];
          for (const image of images) {
            const img = await handleEmail(image);
            imageArray.push(img!);
            // Replace each <img> tag with the processed image URL
            newString = newString.replace(
              /<img[^>]*src=["']data:image\/[a-zA-Z]+;base64,[^"']+["'][^>]*>/,
              `<img src="${img?.secureUrl}" alt="image" title="img" style="max-width: 100%; max-height: 257px; width: 400px; height: auto;" />`
            );
          }

          let tempDiv = document.createElement("div");
          tempDiv.innerHTML = content; // Parse the HTML string into elements

          // Update any remaining content as necessary
          newString = newString.replace(/https:\/\//g, "http://");
          navigate("review", {
            state: {
              ...data,
              type: tempDiv.querySelector("img") ? "mms" : "sms",
              contactIds: contactIds,
              contactGroupIds: contactGroupIds,
              contactGroupNames: contactGroupNames,
              content: newString,
              schedule: combinedDateTime,
              msgId: msgID || msgId,
              medias: imageArray,
              reset: reset(),
            },
          });
          //reset();
          generateResult(
            "success",
            "Review your sms",
            "Review your message before sending or check your drafts to edit the message"
          );
        } else {
          let newString = htmlToPlainText(content);
          let tempDiv = document.createElement("div");
          tempDiv.innerHTML = content; // Parse the HTML string into elements

          navigate("review", {
            state: {
              ...data,
              type: tempDiv.querySelector("img") ? "mms" : "sms",
              contactIds: contactIds,
              contactGroupIds: contactGroupIds,
              contactGroupNames: contactGroupNames,
              content: newString,
              schedule: combinedDateTime,
              msgId: msgID || msgId,
              reset: reset(),
            },
          });
          //reset();
          generateResult(
            "success",
            "Review your sms",
            "Review your message before sending or check your drafts to edit the message"
          );
        }
      } else {
        if (images.length > 0) {
          let newString = content;
          const imageArray: { publicId: any; secureUrl: any }[] | undefined =
            [];
          // Process each image in the images array
          for (const image of images) {
            const img = await handleEmail(image);
            imageArray.push(img!);

            // Replace each <img> tag with the processed image URL
            newString = newString.replace(
              /<img[^>]*src=["']data:image\/[a-zA-Z]+;base64,[^"']+["'][^>]*>/,
              `<img src="${img?.secureUrl}" alt="image" title="img" style="max-width: 100%; max-height: 257px; width: 400px; height: auto;" />`
            );
          }

          // Update any remaining content as necessary

          // Update any remaining content as necessary
          newString = newString.replace(/https:\/\//g, "http://");
          navigate("review", {
            state: {
              ...data,
              type: "mms",
              contactIds: contactIds,
              contactGroupIds: contactGroupIds,
              contactGroupNames: contactGroupNames,
              content: newString,
              msgId: msgID || msgId,
              medias: imageArray,
              reset: reset(),
            },
          });
          generateResult(
            "success",
            "Review your sms",
            "Review your message before sending or check your drafts to edit the message"
          );
        } else {
          let newString = htmlToPlainText(content);
          navigate("review", {
            state: {
              ...data,
              contactIds: contactIds,
              contactGroupIds: contactGroupIds,
              contactGroupNames: contactGroupNames,
              type: "sms",
              content: newString,
              msgId: msgID || msgId,
              reset: reset(),
            },
          });

          generateResult(
            "success",
            "Review your sms",
            "Review your message before sending or check your drafts to edit the message"
          );
        }
      }
    } catch (error) {
      const e = parseClientError(error);
      generateResult("error", e.message || "An unexpected error occurred.");
    }
  };
  const { isTrue, toggle } = useToggle();

  function handleNewGroup() {
    if (!isTrue) {
      toggle();
    }
    setOpenGroup((prev) => !prev);
  }

  useEffect(() => {
    if (msgId) {
      setContent(MessageDetails?.body || ""); // Initialize with message body
    }
  }, [msgId, MessageDetails]);

  const handleDraft = useCallback(async () => {
    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }
  
    debounceTimeout.current = setTimeout(async () => {
      try {
        // Check if too many images are uploaded
        if (images.length > 5) {
          generateResult("error", "You must have at most five images!");
          const updatedContent = content.replace(
            /<img[^>]*src=["']data:image\/[a-zA-Z]+;base64,[^"']+["'][^>]*>/,
            ""
          );
          setContent(updatedContent);
          setImages((prev) => prev.slice(0, -1));
          return;
        }
  
        const formData = new FormData();
        formData.append("title", watch("msgTitle"));
  
        let updatedContent = content;
        const imageArray: { publicId: string; secureUrl: string }[] = [];
  
        // Process images if any
        if (images.length > 0) {
          for (const image of images) {
            const img = await handleEmail(image);
            if (img) {
              imageArray.push(img);
              updatedContent = updatedContent.replace(
                /<img[^>]*src=["']data:image\/[a-zA-Z]+;base64,[^"']+["'][^>]*>/,
                `<img src="${img.secureUrl}" alt="image" title="img" style="max-width: 100%; max-height: 257px; width: 400px; height: auto;" />`
              );
            }
          }
          updatedContent = updatedContent.replace(/https:\/\//g, "http://");
          formData.append("medias", JSON.stringify(imageArray));
        }
  
        // Determine message type
        const tempDiv = document.createElement("div");
        tempDiv.innerHTML = updatedContent;
        const isMMS = tempDiv.querySelector("img") !== null;
        formData.append("type", isMMS ? "mms" : "sms");
  
        // Append processed content
        formData.append("body", htmlToPlainText(updatedContent));
  
        // Prepare additional data
        if (contactGroupIds.length) {
          contactGroupIds.forEach((id) => {
            formData.append("contactGroupIds[]", id);
          });
        }
        if (contactIds.length) {
          contactIds.forEach((id) => {
            formData.append("contactIds[]", id);
          });
        }
  
        formData.append("isDraft", "true");
  
        // Submit draft
        if (watch("msgTitle") && (contactGroupIds.length||contactIds.length)) {
          const fetcherArg: SwrMutationFetcherArgType = {
            token,
            requestOption: {
              method: msgID ? "PATCH" : "POST",
              body: formData,
            },
          };
  
          const result = await submitMsg(fetcherArg);
          hasUpdated.current = true;
  
          if (!msgId && (!msgID || !hasUpdated.current)) {
            setmsgId(result.data.id);
          }
        }
      } catch (error) {
        console.error("Failed to submit draft:", error);
      }
    }, 5000); // 5 seconds delay
  
    return () => {
      if (debounceTimeout.current) {
        clearTimeout(debounceTimeout.current);
      }
    };
  }, [debounceTimeout, images, watch, content, contactGroupIds, contactIds, handleEmail, token, msgID, submitMsg, msgId]);
  
 
  const watchedFields = watch(["msgTitle", "recipient"]);
  async function handleEmail(blob: Blob) {
    type Response = {
      secure_url: string;
      public_id: string;
    };
    if (blob) {
      const formData = new FormData();
      formData.append("media", blob);
      formData.append("type", "Message");
      const response: SuccessDataType<Response> = await uploadImage({
        token,
        requestOption: {
          method: "POST",
          body: formData,
        },
      });
      return {
        secureUrl: response.data.secure_url,
        publicId: response.data.public_id,
      };
    }
  }
  // Effect to call handleDraft whenever content changes
  useEffect(() => {
    handleDraft();
  }, [handleDraft, watchedFields, content]);
  useEffect(() => {
    if (MessageDetails) {
      setValue("msgTitle", MessageDetails.title);
      // setValue("date", dayjs(MessageDetails.scheduledFor).toISOString());

      // setValue("time", dayjs(MessageDetails.scheduledFor).toISOString());
      setValue("from", MessageDetails.senderName);
      const groupId=groups?.docs.find(group=>group.name===MessageDetails.nameOfContactGroups)
      !loadGroups &&setValue("recipient",typeof groupId?._id==="string"?groupId?._id:"")
      setContent(MessageDetails.body || "");
    }
  }, [MessageDetails, groups?.docs, loadGroups, setValue]);
  function handleReset() {
    setValue("date", "");
    setValue("time", "");
  }
  useEffect(() => {
    if (msgId) {
      setmsgId(msgId);
    }
  }, [msgId]);
  const isMobile = useMediaQuery({ query: "(max-width: 800px)" });

  return (
    <>
      <ReusableModal
        title="Schedule message"
        handleModal={handleNewGroup}
        isModalOpen={isMobile && openGroup}
        centered
      >
        <SetSchedule
          handleCombined={handleCombined}
          handleNewGroup={handleNewGroup}
          schedule={msgId ? MessageDetails?.scheduledFor : null}
        />
      </ReusableModal>
      <ReusableDrawer
        title="Schedule message"
        Open={!isMobile && openGroup}
        handleOpen={handleNewGroup}
      >
        <SetSchedule
          handleCombined={handleCombined}
          handleNewGroup={handleNewGroup}
          schedule={msgId ? MessageDetails?.scheduledFor : null}
        />
      </ReusableDrawer>
      {isLoading || error||loadGroups ? (
        <div className={s.spin}>
          <ThreeDots
                  visible={true}
                  height="50"
                  width="50"
                  color="#d52b1e"
                  radius="9"
                  ariaLabel="three-dots-loading"
                  wrapperStyle={{
                    dispaly: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    width: "100%",
                  }}
                  wrapperClass=""
                />
        </div>
      ) : (
        <div className={s.wrapper}>
          <div className={s.emailTitles}>
            <div className={s.msgTitleWrapper}>
              <h1>{watch("msgTitle") || "Untitled"}</h1>
            </div>
            <div className={s.inputWrapper}>
              <h5 className={s.label}>Message title</h5>
              <Controller
                name="msgTitle"
                control={control}
                rules={{ required: "Message title is required" }}
                defaultValue={
                  msgId && MessageDetails ? MessageDetails.title : ""
                }
                render={({ field }) => (
                  <Input
                    {...field}
                    count={{
                      show: true,
                      max: 80,
                    }}
                    className={clsx(s.input, {
                      [s.inputError]: errors.msgTitle,
                    })}
                    maxLength={80}
                  />
                )}
              />
              {!errors.msgTitle && (
                <span className={s.helperText}>
                  <p className={s.titleMsg}>
                    This is just an internal title to help organize and locate
                    your message easily. This does not appear to your audience.
                  </p>
                </span>
              )}
              {errors.msgTitle && (
                <span className={clsx(s.errorMessage)}>
                  {errors.msgTitle.message}
                </span>
              )}
            </div>
            <Recipients  handleContact={handleContacts} handleGroup={handleGroups} />

          </div>

          <div className={s.textEditor}>
            <div className={s.editorBtnGroup}>
              <button
                type="button"
                className={clsx(s.scheduleBtn)}
                onClick={handleNewGroup}
                disabled={isSubmitting}
              >
                Schedule
              </button>
              {/* Button to show scheduling mobile modal */}
              <button
                type="button"
                className={clsx(s.mobileScheduleBtn)}
                onClick={handleNewGroup}
                disabled={isSubmitting}
              >
                Schedule
              </button>
              <button
                type="button"
                className={clsx(s.submitBtn)}
                onClick={handleSubmit(onSubmit)}
                disabled={isSubmitting}
              >
                <Oval
                  visible={isSubmitting}
                  height="16"
                  width="16"
                  color="white"
                  ariaLabel="oval-loading"
                />
                Send now
              </button>
            </div>
            <RichTextEditor
              handleImageUpload={handleImageUpload}
              sms={true}
              value={content}
              handleEdit={handleEdit}
            />
          </div>
        </div>
      )}
    </>
  );
}

export default SMS;
