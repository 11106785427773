import axios from "axios";
import React, { createContext, ReactNode, useContext, useEffect, useState } from "react";

interface ContextType {
  countryCode: string;
}

const CountryCodeContext = createContext<ContextType | undefined>(undefined);

export const CountryCodeProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [countryCode, setCountryCode] = useState("us"); // Default country code

  useEffect(() => {
    const fetchCountryCode = async () => {
      try {
        const response = await axios.get("https://ipapi.co/json/");
        const data = response.data;
        setCountryCode(data.country || "us");
      } catch (error) {
        console.error("Error fetching country code:", error);
      }
    };

    fetchCountryCode();
  }, []); // Empty dependency array ensures it runs only once

  return (
    <CountryCodeContext.Provider value={{ countryCode }}>
      {children}
    </CountryCodeContext.Provider>
  );
};

export const useCountryCode = (): ContextType => {
  const context = useContext(CountryCodeContext);
  if (context === undefined) {
    throw new Error("useCountryCode must be used within a CountryCodeProvider");
  }
  return context;
};
