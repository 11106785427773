import s from "./AddContactForm.module.scss";
import clsx from "clsx";
import { Button } from "antd";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import useSWRMutation from "swr/mutation";
import {
  parseClientError,
  swrMutationFetcher,
  SwrMutationFetcherArgType,
} from "../../libs/api/Endpoints";
import { UseAuthToken } from "../../libs/api/userSession";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useState } from "react";
import ConfirmationModal from "../CofirmationModal/ConfirmationModal";
import { useCountryCode } from "../Context/CountryCode";
import { generateResult } from "../ResultModal/ResultModal";
import { capitalize } from "../../libs/common";
type Inputs = {
  firstName: string;
  lastName: string;
  email: string;
  phoneNo: string;
};

type AddContactProp = {
  handleOpen: () => void;
  groupID?: string | null;
  mutate?: () => void;
};

function AddContactForm({ handleOpen, groupID, mutate }: AddContactProp) {
  const {countryCode}=useCountryCode()
  console.log(countryCode)
  const {
    control,
    register,
    handleSubmit,
    watch,
    formState: { errors, isSubmitting },
    reset,
  } = useForm<Inputs>({ shouldFocusError: false });
  const token = UseAuthToken();
  const [open, setOpen] = useState<boolean>(false);
  const { trigger: addContact } = useSWRMutation(
    "api/contacts",
    swrMutationFetcher
  );
  const handleConfirmationModal = () => {
    setOpen((prev) => !prev);
  };
  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    if (!data.email && !data.phoneNo) {
      handleOpen();
      
      generateResult("error", "Either email or phone number is required");
      
      return;
    }

    const urlEncodedData = new URLSearchParams();
    if (data.firstName)
      urlEncodedData.append("firstName", data.firstName.trimEnd());
    if (data.lastName)
      urlEncodedData.append("lastName", data.lastName.trimEnd());
    if (data.email) urlEncodedData.append("email", data.email.trimEnd());
    if (data.phoneNo)
      urlEncodedData.append("phoneNo", "+" + data.phoneNo.trimEnd());
    if (groupID) urlEncodedData.append("contactGroupId", groupID);
    try {
      if (token) {
        const fetchArg: SwrMutationFetcherArgType = {
          token: token,
          requestOption: {
            method: "post",
            body: urlEncodedData,
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          },
        };
        await addContact(fetchArg);
        handleOpen();
        generateResult("success","Contact Created")
        mutate && mutate() ;
        reset();
      } 
    } catch (error: any) {
      const e = parseClientError(error);
      generateResult("error",capitalize(e.message),  "Unable to create contact. Please try again.",
)

      console.error(e);
    }
  };

  return (
    <>
    
      <ConfirmationModal
        handleCancel={handleOpen}
        open={open}
        reset={reset}
        handleConfirmationModal={handleConfirmationModal}
      />

      <div className={s.wrapper}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className={s.forms}>
            <div className={clsx(s.inputWrapper)}>
              <label htmlFor="firstName" className={clsx(s.inputLabel)}>
                First name <span className={s.optional}>(optional)</span>
              </label>
              <input
                id="firstName"
                className={clsx(s.input, { [s.inputError]: errors.firstName })}
                {...register("firstName")}
              />
              {errors.firstName && (
                <span className={clsx(s.errorMessage)}>
                  {errors.firstName.message}
                </span>
              )}
            </div>
            <div className={clsx(s.inputWrapper)}>
              <label htmlFor="lastName" className={clsx(s.inputLabel)}>
                Last name <span className={s.optional}>(optional)</span>
              </label>
              <input
                id="lastName"
                className={clsx(s.input, { [s.inputError]: errors.lastName })}
                {...register("lastName")}
              />
              {errors.lastName && (
                <span className={clsx(s.errorMessage)}>
                  {errors.lastName.message}
                </span>
              )}
            </div>

            <div className={clsx(s.inputWrapper)}>
              <label htmlFor="email" className={clsx(s.inputLabel)}>
                Email address
              </label>
              <input
                id="email"
                className={clsx(s.input, { [s.inputError]: errors.email })}
                {...register("email", { required: !watch("phoneNo") })}
              />
              {errors.email && (
                <span className={clsx(s.errorMessage)}>
                  {errors.email.message}
                </span>
              )}
            </div>

            <div className={clsx(s.inputWrapper)}>
              <label htmlFor="phoneNo" className={clsx(s.inputLabel)}>
                Phone number
              </label>
              <Controller
                name="phoneNo"
                control={control}
                render={({ field }) => (
                  <PhoneInput

                    {...field} // ensures that the value and onChange are linked
                    country={countryCode.toLowerCase()} // default country
                    enableSearch={true} // Enable searching countries
                    placeholder="Enter phone number"
                    onChange={field.onChange} // Update the field value correctly
                    containerClass={s.phoneInputContainer} // Custom CSS for container
                    dropdownClass={s.phoneInputDropdown} // Custom CSS for dropdown
                    inputClass={clsx(
                      s.input,
                      { [s.inputError]: errors.phoneNo }, // Handle input error class
                      s.PhoneInput, // Additional styles for PhoneInput
                      s.input
                    )} // Input field class
                    buttonClass={s.flagDropdown} // Dropdown for flag and country code
                  />
                )}
              />

              {errors.phoneNo && (
                <span className={clsx(s.errorMessage)}>
                  {errors.phoneNo.message}
                </span>
              )}
            </div>
          </div>
          <div className={s.addContactBtns}>
            <Button
              type="default"
              className={clsx(s.button, s.cancelBtn)}
              onClick={handleConfirmationModal}
            >
              Cancel
            </Button>
            <Button
              type="primary"
              className={clsx(s.button, s.addContactBtn)}
              htmlType="submit"
              loading={isSubmitting}
              disabled={isSubmitting}
            >
              Add Contact
            </Button>
          </div>
        </form>
      </div>
    </>
  );
}

export default AddContactForm;
