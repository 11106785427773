import React from "react";
import { Outlet } from "react-router-dom";
import s from "./Homepage.module.scss";
import Header from "../LandingPage/Header/Header";
import Footer from "./Local Components/Footer";
import TawkMessengerReact from "@tawk.to/tawk-messenger-react";

function HomePage() {
  return (
    <div>
      <TawkMessengerReact
        propertyId="6790eb403a8427326072f627"
        widgetId="1ii71dtqp"
      />
      <div className={s.header}>
        <Header />
      </div>
      <Outlet />
      <Footer />
    </div>
  );
}

export default HomePage;
