import React, { useEffect, useState } from "react";
import s from "./AddContacts.module.scss";
import Card from "./card";
import AddContacts from "../../../../../../global components/Icons/AddContacts/AddContacts";
import UploadContacts from "../../../../../../global components/Icons/UploadContacts/UploadContacts";
import ReusableDrawer from "../../../../../../global components/Drawer/ReuseableDrawer";
import AddContactForm from "../../../../../../global components/AddContactForm";
import ReusableModal from "../../../../../../global components/Modal/Modal";
import UploadContactForm from "../../../../../../global components/UploadContactForm/UploadContactForm";
import ContactsGroup from "../../../../../../global components/Icons/ContactGroup/ContactsGroup";
import AddExistingContacts from "../../../../../../global components/AddExistingContacts";
import { useToggle } from "../../../../../../global components/Context/NavbarContext";
import { useMediaQuery } from "react-responsive";
type AddContactProp = {
  handleModal: () => void;
  groupID: string | null;
  mutate: () => void;
};
function AddContact({ handleModal, groupID, mutate }: AddContactProp) {
  const [open, setOpen] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState<boolean>(false);

  const isMobile = useMediaQuery({ query: "(max-width: 800px)" });

  const [openExistingContact, setOpenExistingContact] =
    useState<boolean>(false);
  const { isTrue, toggle } = useToggle();

  const handleOpenExistingDrawer = () => {
    if (!isTrue) {
      toggle();
    }
    setOpenExistingContact((prev) => !prev);
  };
  const handleUploadModal = () => {
    if (!isTrue) {
      toggle();
    }
    setOpenModal((prev) => !prev);
  };
  function handleOpen() {
    if (!isTrue) {
      toggle();
    }
    setOpen((prev) => !prev);
  }

  const [close, setClose] = useState<boolean>(false);
  function hanleClose() {
    setClose(true);
  }

  return (
    <>
      <ReusableModal
        footer={null}
        isModalOpen={isMobile && open}
        title={"Add contact"}
        centered
        handleModal={handleOpen}
      >
        <AddContactForm mutate={mutate} handleOpen={handleOpen} />
      </ReusableModal>
      <ReusableDrawer
        title={"Add contact"}
        Open={!isMobile && open}
        handleOpen={handleOpen}
      >
        <AddContactForm
          mutate={mutate}
          groupID={groupID}
          handleOpen={handleOpen}
        />
      </ReusableDrawer>
      <ReusableModal
        onClose={hanleClose}
        footer={null}
        isModalOpen={openModal}
        centered
        title={"Upload contacts"}
        width={557}
        subtitle={
          <>
            Add a list of contacts from a csv file. <br></br> The csv file
            should include first name, last name, email address and phone number
          </>
        }
        handleModal={handleUploadModal}
      >
        <UploadContactForm
          Close={close}
          mutate={mutate}
          contactGroupId={groupID}
          handleModal={handleUploadModal}
        />
      </ReusableModal>

      <ReusableDrawer
        title={"Add existing contact"}
        Open={!isMobile && openExistingContact}
        handleOpen={handleOpenExistingDrawer}
      >
        <AddExistingContacts
          mutate={mutate}
          groupID={groupID}
          handleOpen={handleOpenExistingDrawer}
        />
      </ReusableDrawer>
      <ReusableModal
        title={"Add existing contact"}
        isModalOpen={isMobile && openExistingContact}
        handleModal={handleOpenExistingDrawer}
        footer={null}
        centered
      >
        <AddExistingContacts
          mutate={mutate}
          groupID={groupID}
          handleOpen={handleOpenExistingDrawer}
        />
      </ReusableModal>
      <div className={s.wrapper}>
        <Card
          onClick={handleOpenExistingDrawer}
          title="Add existing contacts"
          subtitle="Select from your contact list"
          icon={<ContactsGroup fill="#D52B1E" />}
          handleModal={handleModal}
        />

        <Card
          onClick={handleOpen}
          title="Add a contact"
          subtitle="Quickly add a new individual contact"
          icon={<AddContacts fill="#D52B1E" />}
          handleModal={handleModal}
        />

        <Card
          title="Upload contacts"
          subtitle="Add a list of contacts from a csv file"
          icon={<UploadContacts fill="#D52B1E" />}
          handleModal={handleModal}
          handleUploadModal={handleUploadModal}
        />
      </div>
    </>
  );
}

export default AddContact;
