"use client";
import "./TabsTemplate.scss";
import { Tabs, TabsProps } from "antd";
import cn from "clsx";
import { useMemo } from "react";

interface PropsType extends TabsProps {
  tabItems: TabsProps["items"];
  wrapperClassname?: string;
  withBorderBottom?: boolean;
  onTabChange?: (key: string) => void; // Prop to handle tab change
}

/**
 * @description: A Reusable component that helps display navigable tabs using Antd Tabs component internally
 *
 */
function TabsTemplate({
  tabItems,
  withBorderBottom = false,
  wrapperClassname = "",
  onTabChange, // Receive the onChange function
  ...rest
}: PropsType) {
  const defaultActiveKey = useMemo(() => {
    return tabItems ? tabItems[0].key : "";
  }, [tabItems]);

  return (
    <nav className={cn(wrapperClassname, { 'border-bottom': withBorderBottom })}>
      <Tabs
        defaultActiveKey={defaultActiveKey}
        items={tabItems}
        tabBarGutter={24}
        onChange={onTabChange} // Handle tab change
        {...rest}
      />
    </nav>
  );
}

export default TabsTemplate;
