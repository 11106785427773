import React, { useEffect, useState } from "react";
import SearchInput from "../Forms/SearchInput/SearchInput";
import s from "./AddExistingContacts.module.scss";
import { PaginatedContacts } from "../../libs/Utils/Types/Contact";
import useSWR, { mutate } from "swr";
import {
  parseClientError,
  swrFetcher,
  swrMutationFetcher,
  SwrMutationFetcherArgType,
} from "../../libs/api/Endpoints";
import { UseAuthToken } from "../../libs/api/userSession";
import ContactCheckboxList from "../ContactCheckbox/ContactCheckbox";
import { Button, Divider, message, Spin } from "antd";
import clsx from "clsx";
import useSWRMutation from "swr/mutation";
import { generateResult } from "../ResultModal/ResultModal";
import { singleContactGroup } from "../../libs/Utils/Types/ContactGroup";
import ConfirmationModal from "../CofirmationModal/ConfirmationModal";
import { capitalize } from "../../libs/common";
import Paginate from "../Paginate/Pagination";
type props = {
  handleOpen?: () => void;
  groupID?: string | null;
  mutate?: () => void;
  messages?: boolean;
  handleContacts?: (contactIds: string[]) => void;
};
function AddExistingContacts({
  handleOpen,
  groupID,
  mutate,
  messages = false,
  handleContacts,
}: props) {
  const [search, setSearch] = useState<string>("");
  const [contactsId, setContactsId] = useState<string[]>([]);
  const token = UseAuthToken();
  const [allContactsID, setAllContactsID] = useState<string[]>();
  const [open, setOpen] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageItems, setPageItems] = useState(10);
  const { trigger: addToGroup, isMutating } = useSWRMutation(
    `api/contact-groups/${groupID}`,
    swrMutationFetcher
  );
  const { data } = useSWR<singleContactGroup, any, any>(
    token && groupID ? [`api/contact-groups/${groupID}`, token] : null,
    swrFetcher,

    {
      //refreshInterval: 10000, // Refetch when the window regains focus
      // Refetch every 10 seconds (10000 ms)
      shouldRetryOnError: false, // Disable retrying on errors
    }
  );

  const {
    data: TableContacts,
    isLoading,
    error,
  } = useSWR<PaginatedContacts, any, any>(
    token
      ? [
          "api/contacts",
          token,
          { page: currentPage, limit: pageItems, search: search },
        ]
      : null,
    swrFetcher,
    {
      revalidateOnFocus: true, // Refetch when the window regains focus
      // Refetch every 10 seconds (10000 ms)
      shouldRetryOnError: false, // Disable retrying on errors
    }
  );
  function handleSearch(input: string) {
    setSearch(input);
  }
  const handleSelectionChange = (selectedItems: string[]) => {
    setContactsId((prev) => {
      if (prev !== selectedItems) {
        return selectedItems;
      }
      return prev;
    });
    if (selectedItems.length > 0 && handleContacts) {
      handleContacts(selectedItems);
    }
  };
  

  const addContacts = async () => {
    const jsonBody = { contactIds: contactsId };
    try {
      const fetcherArg: SwrMutationFetcherArgType = {
        token,
        requestOption: {
          body: JSON.stringify(jsonBody),
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
          },
        },
      };
      !messages && (await addToGroup(fetcherArg));
      !messages && generateResult("success", "Contacts added successfully");
      mutate && mutate();
      handleOpen && handleOpen();
    } catch (error) {
      const e = parseClientError(error);
      generateResult(
        "error",
        capitalize(e.message) || "Something went wrong. Try again later"
      );
    }
  };
  const handleConfirmationModal = () => {
    setOpen((prev) => !prev);
    setSearch("");
  };
  const handlePageChange = (page: number, size: number) => {
    setCurrentPage(page);
    setPageItems(size);
  };
  useEffect(() => {
    const allIds = data?.contacts.docs.map((contact) => contact._id);
    setAllContactsID(allIds);
  }, [data?.contacts.docs]);
  if (error) {
    const e = parseClientError(error);
    message.error(capitalize(e.message) || "Something went wrong");
  }
  return (
    <>
      <ConfirmationModal
        handleCancel={() => handleOpen && handleOpen()}
        open={open}
        handleConfirmationModal={handleConfirmationModal}
      />

      <div className={s.wrapper}>
        <div className={s.upper}>
          <SearchInput
            width={425}
            height={45}
            handleSearch={handleSearch}
            className={s.input}
            placeholder="search contact"
          />
          <div className={s.paginate}>
            <Paginate
              onChange={handlePageChange}
              total={TableContacts?.totalDocs || 0}
              pageSize={pageItems}
            />
          </div>
          {TableContacts && !isLoading && !error ? (
            <ContactCheckboxList
              defaultChecked={allContactsID}
              onSelectionChange={handleSelectionChange}
              contacts={TableContacts?.docs}
            />
          ) : (
            <div className={s.spin}>
              <Spin />
            </div>
          )}
        </div>

        {!messages && (
          <div className={s.add_to_group_footer}>
            <Divider />
            <div className={s.addBtns}>
              <Button
                className={clsx(s.button, s.cancelBtn)}
                onClick={handleConfirmationModal}
              >
                Cancel
              </Button>
              <Button
                onClick={addContacts}
                loading={isMutating}
                className={clsx(s.button, s.addContactBtn)}
              >
                Done
              </Button>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default AddExistingContacts;
