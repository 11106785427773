import React, { useState, ComponentPropsWithRef } from 'react';
import { Popover } from 'antd';
import './Popover.scss';

type ReusablePopoverProps = ComponentPropsWithRef<typeof Popover> & {
  children: React.ReactNode;
  text?: React.ReactNode; // Title of the popover
  content?: React.ReactNode; // Content of the popover
  getPopupContainer?: () => HTMLElement;

};

const ReusablePopover = ({ children, text, content,  getPopupContainer,
  ...props }: ReusablePopoverProps) => {
  const [visible, setVisible] = useState(false);

  const handleVisibleChange = (visible: boolean) => {
    // Keep the popover open when clicked inside it, or toggle it based on your need
    setVisible(visible);
  };

  return (
    <Popover
      style={{ padding: 0 }}
      placement="bottomRight"
      content={content}
      visible={visible} // Control visibility
      onOpenChange={handleVisibleChange} // Handle open/close state
      trigger="click" // Open popover on click
      getPopupContainer={getPopupContainer}

      {...props}
    >
      {children}
    </Popover>
  );
};

export default ReusablePopover;
