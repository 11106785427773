import { Pagination } from "antd";
import React, { ComponentPropsWithRef } from "react";
import './Paginate.scss'; // Import the CSS file for custom styles

type PaginateProps = ComponentPropsWithRef<typeof Pagination> & {
  total: number;
  onChange: (page: number, pageSize: number) => void; // Function to handle page change
  pageSize?: number; // Optional prop for initial page size
}

function Paginate({ total, onChange, pageSize = 10 }: PaginateProps) {
  return (
    <div>
      <Pagination
        total={total}
        showTotal={(total, range) =>
          `Showing ${range[0]}-${range[1]} of ${total}`
        }
        defaultPageSize={pageSize}
        onChange={onChange} // Handle page change
        onShowSizeChange={(current, size) => onChange(current, size)} // Handle page size change
        className="custom-pagination" // Add a class to apply custom styles

      />
    </div>
  );
}

export default Paginate;
