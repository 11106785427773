import React, { useEffect, useState } from 'react';
import { Checkbox} from 'antd';
import type { CheckboxProps } from 'antd';
import "./CheckBox.scss"
const CheckboxGroup = Checkbox.Group;

interface CheckboxListProps {
  options: string[];
  defaultChecked?: string[];
  onSelectionChange: (selectedItems: string[]) => void;
}

const CheckboxList: React.FC<CheckboxListProps> = ({ options, defaultChecked = [], onSelectionChange }) => {
  const [checkedList, setCheckedList] = useState<string[]>(defaultChecked);

  const checkAll = options.length === checkedList.length;
  const indeterminate = checkedList.length > 0 && checkedList.length < options.length;

  // Update checkedList whenever options change to ensure it stays in sync
  useEffect(() => {
    setCheckedList(defaultChecked);
  }, [defaultChecked]);

  const onChange = (list: string[]) => {
    setCheckedList(list);
    onSelectionChange(list); // Notify parent component of changes
  };

  const onCheckAllChange: CheckboxProps['onChange'] = (e) => {
    const newList = e.target.checked ? options : [];
    setCheckedList(newList);
    onSelectionChange(newList); // Notify parent component of changes
  };

  return (
    <>
      <Checkbox
        indeterminate={indeterminate}
        checked={checkAll}
        onChange={onCheckAllChange}
      >
        Select all
      </Checkbox>
      <br />
      <CheckboxGroup options={options} value={checkedList} onChange={onChange} />
    </>
  );
};
export default CheckboxList