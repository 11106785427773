import React, { useState } from "react";
import s from "./Contacts.module.scss";
import ContactAvatar from "../../../../../../global components/Icons/ContactAvatar/ContactAvatar";
import { PaginatedContacts } from "../../../../../../libs/Utils/Types/Contact";
import useSWR from "swr";
import { swrFetcher } from "../../../../../../libs/api/Endpoints";
import { UseAuthToken } from "../../../../../../libs/api/userSession";
import SearchInput from "../../../../../../global components/Forms/SearchInput/SearchInput";
import { Spin } from "antd";
import { useLocation } from "react-router-dom";
import { ThreeDots } from "react-loader-spinner";
type ContactsProp = {
  msgId?: string | null;
};
interface EmailState {
  msgID?: string;
}
function Contacts({ msgId }: ContactsProp) {
  const token = UseAuthToken();
  const location = useLocation();
  const { msgID = "" } = (location.state as EmailState) || {};
  const [searchValue, setSearchValue] = useState("");

  const {
    data: contacts,
    isLoading,
    error,
  } = useSWR<PaginatedContacts, any, any>(
    token
      ? [
          `api/messages/${msgID ? msgID : msgId}/recipients`,
          token,
          {
            search: searchValue,
          },
        ]
      : null,
    swrFetcher,
    {
      revalidateOnFocus: true, // Refetch when the window regains focus
      // Refetch every 10 seconds (10000 ms)
      shouldRetryOnError: false, // Disable retrying on errors
    }
  );

  const handleSearch = (value: string) => {
    setSearchValue(value);
  };
  return isLoading || error ? (
    <div className={s.spin}>
     <ThreeDots
        visible={true}
        height="50"
        width="50"
        color="#d52b1e"
        radius="9"
        ariaLabel="three-dots-loading"
        wrapperStyle={{
          dispaly: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          width: "100%",
        }}
        wrapperClass=""
      />
    </div>
  ) : (
    <div className={s.wrapper}>
      <h1 className={s.contactList}>
        Contacts
        <span className={s.contactCount}>({contacts?.totalDocs})</span>
      </h1>
      <div className={s.params}>
        <SearchInput
          width={217}
          placeholder="search"
          className={s.searchInput}
          handleSearch={handleSearch}
        />
      </div>
      <div>
        <div className={s.Contacts}>
          {contacts?.docs.map((contact) => (
            <div className={s.profileDetails}>
              <div className={s.profile}>
                {!contact.firstName || !contact.lastName ? (
                  <ContactAvatar width={20} height={20} />
                ) : (
                  <h1>{contact.firstName[0] + contact.lastName[0]}</h1>
                )}
              </div>
              <div className={s.profileInfo}>
                <p className={s.email}>{contact.email}</p>
                <p className={s.name}>
                  {(contact.firstName||"") + " " + (contact.lastName||"")}
                </p>
                <p className={s.name}>{contact.creditUsed||0} credits used</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Contacts;
