import React from "react";
import s from "./UploadVoice.module.scss";
import DragAndDrop from "../../../../global components/DragAndDrop/DragAndDrop";
import Microphone from "../../../../global components/Icons/Microphone/Microphone";
type UploadVoiceProp = {
  handleVoice: (voice: Blob) => void;
};
function UploadVoice({ handleVoice }: UploadVoiceProp) {
  function handleFileChange(file: string | File) {
    if (file instanceof File) {
      handleVoice(file);
    }
  }
  return (
    <div className={s.wrapper}>
      <DragAndDrop
        type="audio"
        onFileChange={handleFileChange}
        icon={<Microphone width={26} height={22} fill="#D52B1E" />}
      />
      <p>
        Upload your voice message in any of the following formats:
        .mp3, .wav, .m4a, .aac, .ogg, .flac, .aiff, .wma
      </p>
    </div>
  );
}

export default UploadVoice;
